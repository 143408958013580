<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
    components: {},
    // 接下来的使用就跟之前一样，初始化图表，设置配置项
};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width:450px) {
    #app {
        width: 375px;
        height: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    #app {
        width: 100%;
        height: 100%;
    }
}
</style>
