const setRem = () => {
    // document.documentElement.style.fontSize = scale * 16 + "px";
    if (document.documentElement.clientWidth > 450) {
        const scale = 414 / 375;
        document.documentElement.style.fontSize = scale * 16 + "px";
    } else {
        const scale = document.documentElement.clientWidth / 375;
        document.documentElement.style.fontSize = scale * 16 + "px";
    }
};
window,
    (onresize = function () {
        setRem();
    });
