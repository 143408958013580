import { createRouter, createWebHistory } from "vue-router";
const aaa = window.location.pathname;
let routes = [
    {
        path: "/ldy1",
        name: "ldy1",
        component: () => import("@/views/ldy1.vue"),
    },
    {
        path: "/stqyhy",
        name: "stqyhy",
        component: () => import("@/views/stqyhy.vue"),
    },
    // {
    //     path: "/hnydchb",
    //     name: "hnydchb",
    //     component: () => import("@/views/hnydchb.vue"),
    // },
    // {
    //     path:"/hnydmgsp",
    //     name:"hnydmgsp",
    //     component:() => import("@/views/hnydmgsp.vue")
    // },
    // {
    //     path:"/hunanyd25",
    //     name:'hunanyd25',
    //     component:() => import("@/views/hunanyd25.vue")
    // },
    // {
    //     path:"/hunanyd30",
    //     name:'hunanyd30',
    //     component:() => import("@/views/hunanyd30.vue")
    // },
    // {
    //     path:"/shydmgky",
    //     name:'shydmgky',
    //     component:() => import("@/views/shydmgky.vue")
    // },
    // {
    //     path:"/muban",
    //     name:'muban',
    //     component:() => import("@/views/template.vue")
    // },
    {
        path:aaa,
        name:aaa,
        component:() =>import("@/views/template.vue")
    }
];

// 3. 创建路由实例并传递 `routes` 配置。
const router = createRouter({
    // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(process.env.BASE_URL),
    // 将通用路由和模块路由合并
    routes: routes,
});

export default router;
